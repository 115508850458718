import React from 'react';
import Layout from '../../components/layout';
import Questions from '../../components/Questions';

const Pelleve = ({ data, location, ...props }) => {
	return (
		<Layout
			location={location}
			title="Pelleve / Radiofrequenztherapie"
			noSlider
			{...props}
		>
			<p>
				Bei der Pelleve‘ Behandlung wird monopolare Radiofrequenz
				Energie tief in die Bindegewebsschicht der Haut geleitet ohne
				diese zu verletzen.
			</p>
			<p>
				Dabei wird das Gewebe bis auf eine definierte Temperatur von ca.
				42 Grad Celsius über einige Minuten erwärmt. Dadurch ziehen sich
				die Kollagenfasern der Haut zusammen und die Neubildung von
				Kollagen wird angeregt ohne die natürliche Hautbarriere zu
				schädigen.
			</p>
			<p>
				Es entsteht keine Hautverletzung, nur eine stärkere Rötung durch
				die Wärmeeinleitung in die Haut. Es treten keine Ausfallzeiten
				auf da die Rötung sich rasch zurückbildet. Für ein gutes
				Ergebnis sind ca. 3 Behandlungen empfehlenswert.
			</p>

			<Questions title="Pelleve / Radiofrequenztherapie" prefix="um" />
		</Layout>
	);
};

export default Pelleve;
